<template>
  <div class="panel">
    <div class="panel_header tw-flex tw-justify-between tw-items-center">
      <page-header title="资讯管理" />
    </div>
    <div v-loading="loading">
      <el-tabs v-model="activePane">
        <el-tab-pane
          :label="item.name"
          :name="item.id"
          lazy
          v-for="item in tabList"
          :key="item.id"
        >
          <info-table :id="item.id" />
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import infoTable from './info-table'
import * as api from '@/api'
export default {
  data () {
    return {
      // 页面loading
      loading: false,
      activePane: '',
      // 栏目列表
      tabList: []
    }
  },
  components: {
    infoTable
  },
  created () {
    // 获取栏目列表
    this.getInfoColumn()
  },
  methods: {
    // 获取栏目列表
    getInfoColumn () {
      this.loading = true
      api
        .getInfoColumn()
        .then((res) => {
          if (res.data.code === 0) {
            console.log('获取栏目列表', res.data)
            this.tabList = res.data.data.list
            if (this.tabList.length > 0) {
              this.activePane = this.tabList[0].id
            }
          } else {
            this.$message.error(res.data.message)
          }
        })
        .catch((err) => {
          console.log(err)
          this.$message.error('请求出错')
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.panel {
  width: 100%;
  min-width: 1030px;
  min-height: 100%;
  padding: 0 30px;
  background: #ffffff;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  overflow: auto;
  &_header {
    padding: 20px 0;
  }
  &_op {
    padding: 10px 0;
  }
  &_table {
    padding: 10px 0;
  }
  &_pagination {
    margin: 20px 0;
    height: 40px;
  }
}
</style>
